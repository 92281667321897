import { Button, Container, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import SizeButton from '../components/SizeButton';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';

import { useLoaderData, LoaderFunction } from 'react-router-dom';
import { Product, Variant } from '../types/Model';

interface LoaderData {
    product: Product;
}

export const ProductPageLoader: LoaderFunction = async ({ params }): Promise<LoaderData> => {
    const response = await fetch(`/api/products/${params.productId}`);
    const product: Product = await response.json();
    return { product };
}

function addToCart(setProgress: (progress: boolean) => void, product: Product, variant: Variant) {
    setProgress(true);

    const params = retrieveLaunchParams();
    fetch("/api/web_app/cart_add", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: params.initData?.user?.id, product, variant })
    }).finally(() => {
        setProgress(false);
    });
}

export const ProductPage: React.FC = () => {
    const [progress, setProgress] = useState(false);

    const { product } = useLoaderData() as LoaderData;
    const minPrice = product.variants.map(v => v.price).reduce((a: string, b: string) => a < b ? a : b);

    return (
        <Container fixed disableGutters>
            {progress ?
                <CircularProgress /> : // todo: https://mui.com/material-ui/react-progress/#interactive-integration
                <>
                    <Box>
                        <img width={"100%"} src={product.img_url} alt={product.name}></img>
                    </Box>
                    <Box padding={1}>
                        <Typography variant='h5' fontWeight={900}>{product.name}</Typography>
                    </Box>
                    <Box style={{ background: 'url(/images/image.png)' }} borderRadius={2} padding={1} color='#F5FFFA' paddingBottom={2}>
                        <Typography variant='h4' fontWeight={700} color='#F5FFFA'>{minPrice}</Typography>
                        <Button variant='contained' onClick={() => addToCart(setProgress, product, product.variants[0])}>В корзину</Button>
                        <hr></hr>
                        <Box>
                            <Typography variant='h5' fontWeight={900} marginBottom={1}>Размер (EU)</Typography>
                            <Stack direction={'row'} spacing={1} overflow={'scroll'} flexShrink={0}>
                                {product.variants.map(({ size, price }) => (
                                    <SizeButton key={size} size={size} price={price} isSelected={false}></SizeButton>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                </>
            }
        </Container>
    );
};
