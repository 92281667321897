import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField'

import ProductGrid from '../components/ProductGrid';
import { Product } from '../types/Model';

import { useLoaderData, LoaderFunction } from 'react-router-dom';

interface LoaderData {
    products: Product[]
}

export const CatalogPageLoader: LoaderFunction = async (): Promise<LoaderData> => {
    const response = await fetch('/api/products');
    const products: Product[] = await response.json();
    return { products };
};

export const CatalogPage: React.FC = () => {
    const { products } = useLoaderData() as LoaderData;

    return (
        <Container fixed disableGutters>
            <TextField fullWidth id="fullWidth" placeholder="Найти кросовки" margin="normal" />
            <Box sx={{ flexGrow: 1 }}>
                <ProductGrid products={products}></ProductGrid>
            </Box>
        </Container>
    );
};
