import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Product } from '../types/Model';

interface Props {
    product: Product;
};

const ProductCard: React.FC<Props> = ({ product }) => {
    const minPrice = product.variants.map(v => v.price).reduce((a: string, b: string) => a < b ? a : b);

    return (
        <a href={`/products/${product.id}`}>
        <Card square={false} sx={{ boxShadow: 'none' }}>
            <CardMedia
                component="img"
                image={product.img_url}
                alt={product.name}
            />
            <CardContent>
                <Typography variant='h5' fontWeight={800}>{minPrice}</Typography>
                <Typography variant='h6'>{product.name}</Typography>
            </CardContent>
        </Card>
        </a>
    );
};

export default ProductCard;
