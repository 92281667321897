import { Box, Typography } from '@mui/material';
import React from 'react';

interface SizeButtonProps {
    size: string;
    price: string;
    isSelected: boolean;
};

const SizeButton: React.FC<SizeButtonProps> = ({size, price, isSelected}) => {
    return (
        <Box flexShrink={0} width={60} borderRadius={1} border={'1px solid white'} padding={1} style={{background: isSelected ? 'rgba(255, 255, 255, 0.1)' : ''}}>
            <Typography fontWeight={900}>{size}</Typography>
            <Typography fontSize={'12px'} fontWeight={800} color={'#ccc'}>{price}$</Typography>
        </Box>
    );
};

export default SizeButton;